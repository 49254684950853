<template lang="">
    <div class="banners">
        <vueper-slides autoplay :touchable="false">
            <!-- :image="slide.image" -->
            <vueper-slide v-for="(slide, i) in slides" :class="`banner-${i + 1}`" :key="i" :image="slide.image" />
        </vueper-slides>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
    components: { VueperSlides, VueperSlide },
    data() {
        return {
            slides: [
                { image: require('../../../assets/images/banners/1-1.jpg') },
                { image: require('../../../assets/images/banners/2-1.jpg') },
                { image: require('../../../assets/images/banners/3-1.jpg') },
            ],
        };
    },
};
</script>

<style lang="scss">
.banners {
    & .vueperslides__parallax-wrapper {
        width: 100%;
        min-height: 340px;
    }
    & .vueperslides__arrows,
    & .vueperslides__bullets:not(.vueperslides__bullets--outside) {
        color: #000;
    }
    & .vueperslide {
        background-size: 100% 100%;
        background-position: center !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .banners {
        & .vueperslides__parallax-wrapper {
            min-height: 256px;
        }
        & .vueperslide {
            &.banner-1 {
                background-image: url('../../../assets/images/banners/1-2.jpg') !important;
            }
            &.banner-2 {
                background-image: url('../../../assets/images/banners/2-2.jpg') !important;
            }
            &.banner-3 {
                background-image: url('../../../assets/images/banners/3-2.jpg') !important;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .banners {
        & .vueperslides__parallax-wrapper {
            min-height: 250px;
        }
        & .vueperslide {
            &.banner-1 {
                background-image: url('../../../assets/images/banners/1-3.jpg') !important;
            }
            &.banner-2 {
                background-image: url('../../../assets/images/banners/2-3.jpg') !important;
            }
            &.banner-3 {
                background-image: url('../../../assets/images/banners/3-3.jpg') !important;
            }
        }
    }
}
</style>
